import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { navBar } from '@/constant/constants';
import cVanNavBar from '@/components/cVanNavBar.vue';
import { isEmpty, avatarUrl, convertNum } from '@/utils/func';
export default defineComponent({
  props: {},
  components: {
    cVanNavBar: cVanNavBar
  },
  setup: function setup() {
    var router = useRouter();
    var route = useRoute();
    var store = useStore();
    var dataMap = reactive({
      ruleForm: {
        name: '',
        sex: '1',
        number: '',
        grade: '',
        clazz: '',
        id: 0
      },
      queryParam: {
        grade: 0,
        clazz: 0,
        sex: 0,
        avatar_flag: 0
      },
      navBar: {
        title: '学生基础信息',
        color: navBar.BLACK
      },
      hasAvatar: false,
      avatarUrl: ''
    });
    dataMap.ruleForm.id = Number(route.query.stuId);
    dataMap.queryParam.grade = Number(route.query.grade);
    dataMap.queryParam.clazz = Number(route.query.clazz);
    dataMap.queryParam.sex = Number(route.query.sex);
    dataMap.queryParam.avatar_flag = Number(route.query.avatar_flag);

    function getDetail() {
      if (!isEmpty(dataMap.ruleForm.id)) {
        store.dispatch('student/getStudentByIdRequest', {
          id: dataMap.ruleForm.id
        }).then(function (res) {
          console.log(res);
          dataMap.ruleForm.name = res.name;
          dataMap.ruleForm.sex = String(res.sex);
          dataMap.ruleForm.grade = convertNum(res.grade) + '年级';
          dataMap.ruleForm.clazz = String(res.clazz) + '班';
          dataMap.ruleForm.number = res.number;

          if (!isEmpty(res.avatar)) {
            dataMap.hasAvatar = true;
            dataMap.avatarUrl = avatarUrl(res.avatar);
          }
        });
      } else {
        _Notify({
          type: 'danger',
          message: '请选择需要查看的学生'
        });
      }
    }

    var handleGotoEdit = function handleGotoEdit() {
      router.push({
        name: 'createStudent',
        query: {
          stuId: dataMap.ruleForm.id,
          grade: dataMap.queryParam.grade,
          clazz: dataMap.queryParam.clazz,
          sex: dataMap.queryParam.sex,
          avatar_flag: dataMap.queryParam.avatar_flag
        }
      });
    };

    function handleGoBack() {
      router.push({
        name: 'studentList',
        query: {
          grade: dataMap.queryParam.grade,
          clazz: dataMap.queryParam.clazz,
          sex: dataMap.queryParam.sex,
          avatar_flag: dataMap.queryParam.avatar_flag
        }
      });
    }

    function handleGotoCameraFace() {
      router.push({
        name: 'cameraFace',
        query: {
          stuId: dataMap.ruleForm.id,
          number: dataMap.ruleForm.number,
          grade: dataMap.queryParam.grade,
          clazz: dataMap.queryParam.clazz,
          sex: dataMap.queryParam.sex,
          avatar_flag: dataMap.queryParam.avatar_flag
        }
      });
    }

    onMounted(function () {
      getDetail();
    });
    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      handleGotoEdit: handleGotoEdit,
      handleGoBack: handleGoBack,
      handleGotoCameraFace: handleGotoCameraFace
    });
  }
});